import React from "react";
import { graphql } from "gatsby";
import { Block } from "glamor/jsxstyle";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

/**
 * Newsletter page
 *
 * Gives users a chance to sign up for the newsletter without going
 * off my site to Substack where they will likely churn and go elsewhere
 */
const Newsletter = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO
        title={"Newsletter | Conor Dewey"}
        description={``}
        pathname={"newsletter"}
      />
      <h1 style={{ fontSize: "2.8rem", marginBottom: "1.5rem" }}>Newsletter</h1>
      <section>
        <Block
          fontSize="1.4rem"
          lineHeight="2rem"
          className="pageTemplateSubheader"
          fontWeight="500"
          marginBottom="2.8rem"
        >
          Get my favorite articles and any new blog posts straight to your
          inbox. Check out the{" "}
          <a
            href="https://conor.substack.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            last issue
          </a>{" "}
          and let me know how I did.{" "}
        </Block>
      </section>

      <section
        style={{
          marginBottom: "-2.5rem"
        }}
      ></section>
    </Layout>
  );
};

export default Newsletter;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
